<template>
  <v-container fluid class="fill-height">
    <v-row v-if="!cargando" class="my-3" justify="center" align="center">
      <v-col class="d-flex justify-center item-center" cols="12">
        <!-- <v-avatar size="250"> -->
          <v-img
            :aspect-ratio="12/8"
            max-height="250px"
            max-width="250px"
            src="@/assets/Iconos/Logo_ GROBE_.svg"
          />
        <!-- </v-avatar> -->
      </v-col>
      <v-col xl="4" lg="4" md="4" sm="11" cols="11">
        <v-row v-if="!isLogged" justify="center" align="center">
          <v-col class="text-center" cols="12">
            <v-text-field
              outlined
              :label="$t('login.email')"
              hide-details="auto"
              @blur="$v.parametros.correo.$touch()"
              :error-messages="errorCorreo"
              v-model="parametros.correo"
            ></v-text-field>
          </v-col>
          <v-col class="text-center" cols="12">
            <v-text-field
              :type="show_pass ? '' : 'password'"
              outlined
              :label="$t('login.pass')"
              hide-details="auto"
              :append-icon="
                show_pass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="show_pass = !show_pass"
              @blur="$v.parametros.pass.$touch()"
              :error-messages="errorPass.length > 0 ? errorPass : errorLogin"
              v-model="parametros.pass"
            ></v-text-field>
          </v-col>
          <v-col class="text-center" xl="6" lg="6" md="6" sm="11">
            <v-btn @click="iniciar(parametros)" block color="info"> {{$t('login.title')}} </v-btn>
          </v-col>
        </v-row>
        <v-row v-else justify="center" align="center">
            <v-col class="text-center" xl="6" lg="6" md="6" sm="11">
                <v-btn @click="salir()" block color="info"> {{$t('login.close')}} </v-btn>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else justify="center" align="center">
        <v-col class="text-center" cols="12">
            <v-progress-circular
                indeterminate
                color="primary"
                size="150"
            ></v-progress-circular>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { email, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';

export default {
  mixins: [validationMixin],
  data() {
    return {
      show_pass: false,
      parametros: {
        correo: '',
        pass: '',
      },
      errorLogin: [],
      isLogged: false,
      cargando: true,
    };
  },
  methods: {
      ...mapActions("login", ["Login", "validar", "cerrarSesion"]),
      async iniciar(key) {
          if(!this.$v.parametros.$dirty) {
              return;
          }
          this.cargando = true;
          let respuesta = await this.Login(key);
          if(respuesta.error){
              this.errorLogin.push(respuesta.error);
              this.cargando = false;
              return;
          }
          this.session();
      },
      async salir() {
          await this.cerrarSesion();
          this.session();
      },
      validarInicialSession(inicial){
          if(inicial){
              setTimeout(() =>
                    this.session()
                , 1000);
          } else {
              this.session();
          }
      },
      async session() {
        this.cargando = true;
        const valor = await this.validar();
        this.isLogged = valor;
        this.cargando = false;
    },
  },
  computed: {
    errorCorreo() {
      this.errorLogin = [];
      let errores = [];
      if (!this.$v.parametros.correo.$dirty) {
        return errores;
      }
      if (!this.$v.parametros.correo.required) {
        errores.push(this.$t('login.required'));
      }
      if (!this.$v.parametros.correo.email) {
        errores.push(this.$t('login.format'));
      }
      return errores;
    },
    errorPass() {
      this.errorLogin = [];
      let errores = [];
      if (!this.$v.parametros.pass.$dirty) {
        return errores;
      }
      if (!this.$v.parametros.pass.required) {
        errores.push(this.$t('login.required'));
      }
      return errores;
    },
  },
  validations: {
    parametros: {
      correo: {
        required,
        email,
      },
      pass: {
        required,
      },
    },
  },
  mounted() {
      this.validarInicialSession(true);
  }
};
</script>
